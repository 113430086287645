// Include some flags that are used to control the CSS output
// of things like resets, font families, and resets
$css--font-face: true;
$css--helpers: true;
$css--body: true;
$css--use-layer: true;
$css--reset: true;
$css--typography: true;
$css--plex: true;

@import '@carbon/themes/scss/themes';

// Use the gray 10 theme
$carbon--theme: $carbon--theme--g90;
@include carbon--theme();

// Include each component as you start using it in your project
@import 'carbon-components/scss/components/date-picker/date-picker';

@font-face {
  font-family: 'AT Surt Medium';
  src: url('./fonts/AT_Surt_Medium.otf')
}
